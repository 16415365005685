import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = { 
    closable: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    this.hide = this.hide.bind(this)
    document.addEventListener('CustomModalClose', this.hide)
    const options = {
      onHide: () => this.onHide(),
      closable: this.closableValue,
      backdropClasses: 'bg-slate-900 bg-opacity-50 fixed inset-0 z-50 backdrop-blur-sm',
    }
    this.modal = new Modal(this.element, options)
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  onHide() {
    const modalFrame = document.getElementById('modal_frame')
    modalFrame.removeAttribute('src')
    modalFrame.replaceChildren()
    this.element.remove()  
  }

  disconnect() {
    document.removeEventListener('CustomModalClose', this.hide)
  }

}